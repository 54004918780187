<template>
   <div id="update-link" class="space-evenly one">
      <div class="rsvp-item">
        <label>Banner</label>
        <div class="center-content">
          <button :class="{
              'toggle-enabled': showBanner !== true,
              toggle: true,
            }"
            @click="setShowBanner(false)">Hide</button>
          <button :class="{
              'toggle-enabled': showBanner === true,
              toggle: true,
            }"
            @click="setShowBanner(true)">Show</button>
        </div>
      </div>
      <div class="rsvp-item">
        <label>URL</label>
        <input
          v-model="vUrl"
          @keyup.enter="updateLink()"
          type="text"
          placeholder="URL" />
      </div>
      <div class="rsvp-item">
        <label>Password</label>
        <input
          v-model="password"
          @keyup.enter="updateLink()"
          type="password"
          placeholder="Password" />
      </div>
      <div class="center-content">
        <loader-button
          ref="updatingLinkButton"
          @click="updateLink()">Update</loader-button>
      </div>
    </div>
</template>

<script>
import LoaderButton from '@/components/LoaderButton.vue';
import videoLinkApi from '../api/vlink';

export default {
  name: 'Rsvp',
  components: {
    LoaderButton,
  },
  data() {
    return {
      iPhoneVersion: Infinity,
      isUpdatingLink: false,
      isVerifyingParty: false,
      showBanner: false,
      verifyName: 'zac',
      vUrl: '',
      usingIphone: false,
    };
  },
  props: {
  },
  mounted() {
    this.setLinkInformation();
    const ua = window.navigator.userAgent;

    this.isOniOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    this.matchesWebKit = !!ua.match(/WebKit/i);
    const versionMatches = ua.match(/Version\/([0-9.]+)/);
    if (versionMatches) {
      this.iPhoneVersion = parseInt(versionMatches[1], 10);
    }

    this.usingIphone = this.isOniOS && this.matchesWebKit && !ua.match(/CriOS/i);
  },
  computed: {
  },
  methods: {
    setShowBanner(value) {
      this.showBanner = value;
    },
    resetFormToUpdateRsvp() {
      this.showAcceptedPage = false;
      this.showAlreadyRsvpdPage = false;
      this.showMissYouPage = false;
      this.needsReview = false;
      this.isUpdatingRsvp = true;
      this.isOnRsvpList = true;
    },
    resetPartyVerification() {
      this.rsvpVerificationFailed = false;
      this.isUpdatingLink = false;
    },
    setLinkInformation() {
      videoLinkApi.getCurrentLink().then((response) => {
        this.vUrl = response.data.vUrl;
        this.showBanner = response.data.isShowing;
      });
    },
    updateLink() {
      if (this.isUpdatingLink) {
        return;
      }
      this.isUpdatingLink = true;
      const { updatingLinkButton } = this.$refs;
      updatingLinkButton.startLoading();

      // determine what this RSVP is all about

      this.updateLinkRequest({
        link: {
          password: this.password,
          url: this.url,
          showingBanner: this.showBanner === 'Show',
        },
      });
    },
    async updateLinkRequest(status, data) {
      // const result = await rsvpApi.submit(data);
      const result = await videoLinkApi.updateLink(data);
      this.isUpdatingLink = false;

      const { updatingLinkButton } = this.$refs;
      updatingLinkButton.stopLoading();

      if (result.status !== 204) {
        this.showErrorPage = true;
      }
    },
    async rsvpVerification() {
      // Make request to api to get party member
      try {
        const updatingButtonLoader = this.$refs.updatingLinkButton;
        updatingButtonLoader.startLoading();
        const response = await videoLinkApi.getCurrentLink();
        updatingButtonLoader.stopLoading();
        this.party = response.data;
        this.changeSeatCount = this.party.chairCount;
        this.changeLapInfantCount = this.party.babyCount;
        this.showAlreadyRsvpdPage = this.party.hasSubmittedRsvp;
        this.needsReview = this.party.needsReview;
        this.isOnRsvpList = true;
      } catch (e) {
        if (e.response.status === 404) {
          const updatingButtonLoader = this.$refs.updatingLinkButton;
          updatingButtonLoader.stopLoading();
          this.isOnRsvpList = false;
          this.rsvpVerificationFailed = true;
        }
      }
    },
    setAllergyText(isEntering) {
      if (this.allergiesAndDietaryRestrictions === 'Eggs, Peanuts, Milk, etc.'
          && isEntering) {
        this.disableAllergyFakeholder = false;
        this.allergiesAndDietaryRestrictions = '';
        return;
      }
      if (this.allergiesAndDietaryRestrictions.trim() === ''
          && !isEntering) {
        this.disableAllergyFakeholder = false;
        this.allergiesAndDietaryRestrictions = 'Eggs, Peanuts, Milk, etc.';
        return;
      }
      this.disableAllergyFakeholder = true;
    },
    toggleRSVPVisibility() {
      this.rsvpShowing = !this.rsvpShowing;
      if (!this.isOnRsvpList) {
        this.$nextTick(() => {
          this.$refs.rsvpVerify.focus();
        });
      }
    },
  },
  watch: {
    allergiesAndDietaryRestrictions(value) {
      if (value !== 'Eggs, Peanuts, Milk, etc.') {
        this.disableAllergyFakeholder = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
