<template>
  <div class="debug">
    <h1>Update Link</h1>
    <update-linker></update-linker>
  </div>
</template>

<script>
// @ is an alias to /src
import UpdateLinker from '@/components/UpdateLinker.vue';

export default {
  name: 'Home',
  components: {
    UpdateLinker,
  },
  data() {
    return {
      userAgent: '',
      isOniOS: false,
      matchesWebKit: false,
      matchesiOSSafari: false,
      version: 'Unknown',
    };
  },
  methods: {
  },
  mounted() {
    let ua = 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_4 like Mac OS X) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.0 Mobile/14G61 Safari/602.1';
    ua = 'Mozilla/5.0 (iPhone; CPU iPhone OS 12_5_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1';
    ua = 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/604.1';
    ua = window.navigator.userAgent;
    this.userAgent = ua.toString();
    this.isOniOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    this.matchesWebKit = !!ua.match(/WebKit/i);
    const versionMatches = ua.match(/Version\/([0-9.]+)/);
    if (versionMatches) {
      [, this.version] = versionMatches;
    }
    this.matchesiOSSafari = this.isOniOS && this.matchesWebKit && !ua.match(/CriOS/i);
  },
};
</script>
